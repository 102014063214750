@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&family=Roboto:wght@400;500;700&display=swap');
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard.css');

:root,
*,
html {
  font-family: 'Poppins', 'Pretendard', sans-serif;
  font-weight: 400;
}

body {
  background-color: #000;
}

.h1 {
  font-weight: 700;
  font-size: 80px;
}
.h2 {
  font-weight: 700;
  font-size: 46px;
}
.h3 {
  font-weight: 700;
  font-size: 40px;
}
.h4 {
  font-weight: 500;
  font-size: 30px;
}

.subtitle1 {
  font-weight: 500;
  font-size: 36px;
}
.subtitle2 {
  font-weight: 500;
  font-size: 24px;
}
.subtitle3 {
  font-weight: 400;
  font-size: 24px;
}
.body1 {
  font-weight: 500;
  font-size: 20px;
}
.body2 {
  font-weight: 400;
  font-size: 20px;
}
.body3 {
  font-weight: 400;
  font-size: 16px;
}
.caption {
  font-weight: 400;
  font-size: 14px;
}

// mobile

.m-subtitle {
  font-weight: 700;
  font-size: 24px;
}
.m-subtitle1 {
  font-weight: 500;
  font-size: 16px;
}
.m-subtitle2 {
  font-weight: 500;
  font-size: 14px;
}
.m-subtitle3 {
  font-weight: 500;
  font-size: 10px;
}
.m-body {
  font-weight: 400;
  font-size: 12px;
}
.m-body2 {
  font-weight: 400;
  font-size: 10px;
}



// editor
.ck.ck-editor__editable:not(.ck-editor__nested-editable) {
  min-height: 400px;
  max-height: 100%;
  height: 100%;

  @media (max-width: 1024px) {
    color: #000;
    min-height: 300px;
    font-size: 16px;
  }
}
.ck-content{

  ul{

    li{
      list-style: none;
    }
  }
}


